import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "axios";

import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { ReactComponent as UploadFile } from "../assets/icons/Uploader/UploadFile.svg";

// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
import moment from "moment";


export default function Copilot() {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [input, setInput] = useState("");


async function getResult(){
  if(input){
  setResult("")
  let result= await axios.post("https://analytics-api-llm.labeah.ai/get_analytics/", {
    query: input,
  })
  setInput("")
  // alert(result.data['Answer'])
  setResult(result.data['Answer'])

}
else{alert("please enter your query")}
}


  return (
    <>

      <Layout>
        <div className="min-h-[90vh]">
          <div className="bg-white border rounded-xl p-5 text-center font-bold">
            Copilot For Analytics
          </div>
          <div className=" center text-center p-4  bg-gray-50">
            <textarea 
            value={input}
            onChange={((e)=>{
              setInput(e.target.value)
            })}
            className="rounded w-[1000px] h-[400px] p-4 shadow" placeholder="Ask me Anything, I'm up to date over your data ">
              asdasd
            </textarea>

            <div class="mt-4 flex items-center justify-center gap-x-6">
            
            <button
              onClick={(e) => {
                getResult();
              }}
              type="submit"
              class="w-[200px] rounded-full bg-secondary px-3 py-2 text-sm font-semibold text-white"
            >
              Analyze
            </button>
            </div>
          </div>

{
  result?
  <div className="bg-white border rounded-xl p-5 text-center font-bold">
  Result: <br/><br/>
  {result}
</div>:null
}
         
         

        </div>
      </Layout>
    </>
  );
}
