import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
// import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa';
import Layout from "../components/Layout";
// var RegionsPlugin = require("wavesurfer.js/dist/plugin/wavesurfer.regions.min.js");
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
// import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js"
// import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import TimelinePlugin from "https://unpkg.com/wavesurfer.js@7/dist/plugins/timeline.esm.js";

const Waveform = ({}) => {
  const [currentChunk, setCurrentChunk] = useState({
    sentiment: "Neurtral 😐",
  });

  const audio = "audio1.wav";
  const containerRef = useRef();
  const waveSurferRef = useRef();
  const [isPlaying, toggleIsPlaying] = useState(false);
  let waveSurfer = null;

  useEffect(() => {
    waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: "violet",
      progressColor: "purple",
      responsive: true,
      barWidth: 3,
      barHeight: 2,
      cursorWidth: 2,
      plugins: [
        RegionsPlugin.create({
          regionsMinLength: 2,
          regions: [
            {
              start: 1, // in seconds
              end: 18,
              loop: false,
              color: "hsla(200, 50%, 70%, 0.6)",
              content: "Helloooooo",
            },
            {
              start: 50, // in seconds
              end: 90,
              loop: false,
              color: "hsla(200, 50%, 70%, 0.6)",
              content: "Helloooooo",
            },
            {
              start: 110,
              end: 140,
              loop: false,
              color: "hsla(200, 50%, 70%, 0.6)",
              minLength: 1,
              content: "Helloooooo",
            },
          ],
          dragSelection: {
            slop: 5,
          },
        }),
      ],
    });

    waveSurfer.load(audio);
    waveSurfer.on("ready", () => {
      // waveSurfer.play()
      var timeline = Object.create(WaveSurfer.Timeline);

      timeline.init({
        wavesurfer: waveSurfer,
        container: waveSurferRef,
      });
      waveSurferRef = waveSurfer;

      // waveSurferRef.current.enableDragSelection({loop: true});

      // waveSurferRef.current.addRegion({id:1,start:1,end:20, color: 'hsla(100, 100%, 30%, 0.1)'})
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  return (
    <Layout>
      <div class="pl-4 pr-4 sm:ml-64 ">
        <div className="mt-10">
          <WaveSurferWrap>
            <button
              onClick={() => {
                waveSurferRef.current.playPause();
                toggleIsPlaying(waveSurferRef.current.isPlaying());
              }}
              type="button"
            >
              {isPlaying}
            </button>
            <div ref={containerRef} />
          </WaveSurferWrap>
          <button
            className="bg-blue-300 p-1 rounded mt-2"
            onClick={() => {
              // toggleIsPlaying(true);
              // waveSurferRef.current.isPlaying();
              waveSurfer.play();
              // if(waveSurferRef.isPlaying()){
              //   toggleIsPlaying(false)
              //   waveSurferRef.pause()
              // }else{
              //   toggleIsPlaying(true)
              //   waveSurferRef.play()
              // }

              // toggleIsPlaying(waveSurferRef.current.isPlaying());
            }}
          >
            Play/Pause
          </button>
        </div>
      </div>
    </Layout>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  button {
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    background-color: white;
  }
`;

export default Waveform;
