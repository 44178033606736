import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
// import { useState } from 'react'
import DeleteModal from "../components/DeleteModal";
import EditVideoModal from "../components/EditVideoModal";
// const fs = require("fs");
import { ReactComponent as MicIcon } from "../assets/icons/mic.svg";

import React, { useEffect, useState, useRef } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import WaveSurfer from "wavesurfer.js";
import { Chart } from "react-google-charts";

import axios from "axios";

const SPEECH_KEY = "7155564f908546888450b2ae943adedd";
const SPEECH_REGION = "eastus";

function AudioTranscription() {
  const [transcriptionSentences, setTranscriptionSentences] = useState([]);
  const [transcription2, setTranscription2] = useState("");
  const [transcriptionSentences2, setTranscriptionSentences2] = useState([]);

  // const [ner, setNer] = useState([]);
  const [ner2, setNer2] = useState([]);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [waveform, setWaveform] = useState(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [transcription, setTranscription] = useState(`
  حياك الله معك المساعد الافتراضي الذكي  من مستشيات المركز التخصصي الطبي.لحظات لمراجعة البيانات، كيف اقدر اساعدك؟
  أبي احجز موعد
  أبشر في اي مستشفي من مستشفياتنا تحب احجز مستشفى طريق الملك فهد  او مستشفى طريق الملك عبدالله
  الملك فهد
  فهمت انك تبي مستشفى طريق الملك فهد
  نعم
  في اي عيادة حاب احجزلك
  التغذية
  افا ليه زعلان علينا
  التغذية
  فهمت انك تبي احجزلك موعد في عيادة التغذية صحيح؟
  نعم
  هل في طبيب محدد
  أبي احمد العيسى
  `);
  const [ner, setNer] = useState([
    ["منشأة - ", "مستشفى الملك فهد"],
    ["رقم وطني - ", "١٠١٠١٠١٠"],
    ["وقت - ", "١٢ ظهرا "],
    ["تاريخ - ", "٢٠/١٠/٢٠٢٣"],
  ]);
  const [sentiment, setSentiment] = useState("Positive 😃");
  const [gender, setGender] = useState("Male");

  const [messages, setMessages] = useState([
    {
      text: "حياك الله معك المساعد الافتراضي الذكي  من مستشيات المركز التخصصي الطبي.لحظات لمراجعة البيانات، كيف اقدر اساعدك؟",
      isChatOwner: false,
    },
    {
      text: "أبي احجز موعد",
      isChatOwner: true,
    },
    {
      text: "أبشر في اي مستشفي من مستشفياتنا تحب احجز مستشفى طريق الملك فهد  او مستشفى طريق الملك عبدالله",
      isChatOwner: false,
    },
    {
      text: "الملك فهد",
      isChatOwner: true,
    },
    {
      text: "فهمت انك تبي مستشفى طريق الملك فهد",
      isChatOwner: false,
    },
    {
      text: "نعم",
      isChatOwner: true,
    },
    {
      text: "في اي عيادة حاب احجزلك",
      isChatOwner: false,
    },
    {
      text: "التغذية",
      isChatOwner: true,
    },
    {
      text: "افا ليه زعلان علينا",
      isChatOwner: false,
    },
    {
      text: "التغذية",
      isChatOwner: true,
    },
    {
      text: "فهمت انك تبي احجزلك موعد في عيادة التغذية صحيح؟",
      isChatOwner: false,
    },
    {
      text: "نعم",
      isChatOwner: true,
    },
    {
      text: "هل في طبيب محدد",
      isChatOwner: false,
    },
    {
      text: "أبي احمد العيسى",
      isChatOwner: true,
    },
    {
      text: "ممكن تزودني بالرقم الوطني؟",
      isChatOwner: false,
    },
    {
      text: "١٠١٠١٠١٠",
      isChatOwner: true,
    },
    {
      text: "ممكن تزودني بتاريخ الموعد؟",
      isChatOwner: false,
    },
    {
      text: "٢٠/١٠/٢٠٢٣",
      isChatOwner: true,
    },
  ]);

  const transcribeAudio = async () => {
    const subscriptionKey = SPEECH_KEY;
    const serviceRegion = SPEECH_REGION;
    const language = "ar-SA";

    const speechConfig = sdk.SpeechConfig.fromSubscription(
      subscriptionKey,
      serviceRegion
    );
    speechConfig.speechRecognitionLanguage = language;

    // Create a push stream with audio data
    const audioStream = sdk.AudioInputStream.createPushStream();
    audioStream.write(audioBuffer);
    audioStream.close();

    // Create a recognizer
    const audioConfig = sdk.AudioConfig.fromStreamInput(audioStream);
    const conversationTranscriber = new sdk.ConversationTranscriber(
      speechConfig,
      audioConfig
    );

    conversationTranscriber.sessionStarted = function (s, e) {
      console.log("SessionStarted event");
      console.log("SessionId:" + e.sessionId);
    };
    conversationTranscriber.sessionStopped = function (s, e) {
      console.log("SessionStopped event");
      console.log("SessionId:" + e.sessionId);
      conversationTranscriber.stopTranscribingAsync();
    };
    conversationTranscriber.canceled = function (s, e) {
      console.log("Canceled event");
      console.log(e.errorDetails);
      conversationTranscriber.stopTranscribingAsync();
    };
    conversationTranscriber.transcribed = async function (s, e) {
      let updatedMessages = messages;
      let current_sentiment = "p"; //await getSentiment(e.result.text)
      updatedMessages.push({
        text: e.result.text,
        isChatOwner: !e.result.speakerId.toString().includes("Guest-1"),
        speakerId: e.result.speakerId,
        // sentiment: current_sentiment
      });
      setTimeout(() => {
        setMessages(updatedMessages);
        setTranscription(
          (prevTranscription) => prevTranscription + " " + e.result.text
        );
      }, 400);
      // text: "hi",
      // isChatOwner: true

      // if (e.result.speakerId.toString().includes("Guest-1")) {
      //   setTranscription2((prevTranscription) => prevTranscription + ' ' + e.result.text);
      //   let updated_transcriptionSentences2 = transcriptionSentences2
      //   updated_transcriptionSentences2.push({ sentence: e.result.text, sentiment: await getSentiment(e.result.text) })
      //   setTranscriptionSentences2(updated_transcriptionSentences2)
      // } else {

      //   setTranscription((prevTranscription) => prevTranscription + ' ' + e.result.text);
      //   let updated_transcriptionSentences = transcriptionSentences
      //   updated_transcriptionSentences.push({ sentence: e.result.text, sentiment: await getSentiment(e.result.text) })
      //   setTranscriptionSentences(updated_transcriptionSentences)

      // }

      // ={"privResultId":"F876D204FB9A4C4684147BDDBAFC649E","privReason":3,"privText":"أبشر في أي مستشفى من مستشفياتنا، تحب أحجزلك مستشفى، طريق الملك فهد، أو مستشفى طريق الملك عبد الله.","privDuration":66100000,"privOffset":167000000,"privJson":"{\"Id\":\"6280f330cd434edf94996ae5902b4d4a\",\"RecognitionStatus\":\"Success\",\"DisplayText\":\"أبشر في أي مستشفى من مستشفياتنا، تحب أحجزلك مستشفى، طريق الملك فهد، أو مستشفى طريق الملك عبد الله.\",\"Offset\":167000000,\"Duration\":66100000,\"Channel\":0,\"Type\":\"ConversationTranscription\",\"SpeakerId\":\"Guest-1\"}","privProperties":{"privKeys":["SpeechServiceResponse_JsonResult"],"privValues":["{\"Id\":\"6280f330cd434edf94996ae5902b4d4a\",\"RecognitionStatus\":\"Success\",\"DisplayText\":\"أبشر في أي مستشفى من مستشفياتنا، تحب أحجزلك مستشفى، طريق الملك فهد، أو مستشفى طريق الملك عبد الله.\",\"Offset\":167000000,\"Duration\":66100000,\"Channel\":0,\"Type\":\"ConversationTranscription\",\"SpeakerId\":\"Guest-1\"}"]},"privSpeakerId":"Guest-1"}

      // console.log("TRANSCRIBED: Text=" + JSON.stringify(e.result));
      // console.log("TRANSCRIBED: Text=" + (parseFloat(e.result.duration) / 10000000)+ (parseFloat(e.result.offset)/10000000));
      // console.log("TRANSCRIBED: Text=" + (e.result.languageDetectionConfidence));
    };

    conversationTranscriber.startTranscribingAsync(
      function () {
        setTimeout(() => {
          waveform.play();
          setIsPlaying(true);
        }, 3000);
      },
      function (err) {
        console.trace("err - starting transcription: " + err);
      }
    );
  };

  useEffect(() => {
    // Initialize WaveSurfer when the component mounts
    const wavesurfer = WaveSurfer.create({
      container: wavesurferRef.current,
      // waveColor: 'violet',
      // progressColor: 'purple',
      waveColor: "violet",
      progressColor: "purple",
      responsive: true,
      barWidth: 3,
      barHeight: 2,
      cursorWidth: 2,
    });
    wavesurfer.load("/40.wav");

    // waveform.load(URL.createObjectURL("/40.wav"));

    // wavesurfer.on('interaction', () => {
    wavesurfer.play();
    // })

    // waveform.load("/Users/zaidsallam/Desktop/work/Future Look/speech_analytics_front/public/40.wav")
    // alert("hi")
    // wavesurfer.play()
    setWaveform(wavesurfer);

    // Clean up WaveSurfer when the component unmounts
    return () => {
      wavesurfer.destroy();
    };
  }, []);

  useEffect(() => {
    // getNer(transcription)
  }, [transcription]);

  useEffect(() => {
    // getSentiment(transcription)
  }, [transcription]);

  async function getNer(transcription) {
    if (transcription)
      axios
        .post(
          "https://lab.labeah.ai/api/predictions/text",
          {
            endpoint: "http://150.230.51.94",
            phrase: transcription,
          },
          {
            headers: { "Content-Type": "text/plain" },
          }
        )
        .then((response) => {
          console.log(response.data.result.results);
          setNer(response.data.result.results);
        })
        .catch((err) => {
          console.log(err.message);
        });
  }

  async function getSentiment(transcription) {
    if (transcription) {
      let response = await axios.post("http://localhost:5000/analyze", {
        statement: transcription,
      });
      setSentiment(response.data.result);
      return response.data.result;
    }
  }

  async function getGender(file) {
    const formData = new FormData();
    formData.append("audio", file, "audio.wav");

    // Configure the request headers
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // Send the audio data to the specified endpoint
    const response = await axios.post(
      "http://207.127.97.92",
      formData,
      axiosConfig
    );

    // Retrieve the results from the response
    // const results = response.data.result;
    setGender(response.data.result);
    // console.log("results===========================")
    // console.log(results)
    // console.log("results===========================")
  }

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      getGender(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the audioBuffer when the file is read
        setAudioBuffer(new Uint8Array(e.target.result));
        if (waveform) {
          // Load the selected audio file into WaveSurfer
          waveform.load(URL.createObjectURL(file));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (audioBuffer) {
      transcribeAudio();
    }
  }, [audioBuffer]);

  return (
    <Layout>
      <div class="pl-4 pr-4 sm:ml-64 ">
        <div className="mt-4">
          <div class="flex flex-col">
            <div class="pt-2">
              {/* <button onClick={((e) => {
                setIsPlaying(!isPlaying)
                if (!isPlaying) {
                  waveform.play()
                } else {
                  waveform.pause()
                }
              })} className="">{isPlaying == true ?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                </svg>

                }</button> */}
              {/* <input type="file" accept="audio/wav" onChange={handleFileInputChange} /> */}
            </div>
            {/* <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
<input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file"/> */}
          </div>

          <div ref={wavesurferRef}></div>

          {/* <p>Transcription: {transcription}</p> */}
        </div>

        <button
          type="button"
          class="mt-4 mb-8 bg-primary font-medium rounded-lg text-sm px-4 py-2 "
        >
          {isPlaying == true ? (
            <div
              onClick={(e) => {
                waveform.pause();
                setIsPlaying(false);
              }}
              className="flex text-white font-bold "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                />
              </svg>
              Pause
            </div>
          ) : (
            <div
              onClick={(e) => {
                waveform.play();
                setIsPlaying(true);
              }}
              className="flex text-white font-bold "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
              Play
            </div>
          )}
        </button>

        <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1 ">
            <div className="h-[75vh] overflow-auto border border-gray-300 rounded p-2">
              {messages.map((message, index) => (
                // <div>
                // <span className="text-xs text-red-600">
                //        {message.speakerId}
                //      </span>
                //     <span className="text-md">{message.text}</span>
                //  </div>
                <div
                  key={index}
                  className={`py-2 flex flex-row w-full ${
                    !message.isChatOwner || index == 0
                      ? "justify-start"
                      : "justify-end"
                  }
                    `}
                >
                  <div
                    className={`${message.isChatOwner ? "order-2" : "order-1"}`}
                  ></div>
                  <div
                    className={`px-2 w-fit py-3 flex flex-col 
                    ${
                      !message.isChatOwner || index == 0
                        ? "bg-gray-400"
                        : " bg-primary"
                    }
                     rounded-lg text-white ${
                       message.isChatOwner ? "order-1 mr-2" : "order-2 ml-2"
                     }`}
                  >
                    <span className="text-xs text-gray-200">
                      {message.isChatOwner ? "Speaker 1" : "Agent"}
                    </span>
                    <span className="text-md">{message.text}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div class="col-span-1  p-4">
            <dt class="text-lg font-medium leading-6 text-gray-700">
              Full Transcription
            </dt>
            {/* <!-- Content for the second column --> */}
            <div class="w-70 h-[40%] overflow-y-auto p-4 bg-gray-100 rounded border border-gray-300">
              {transcription}
            </div>

            <dt class="text-lg font-medium leading-6 text-gray-700 mt-4">
              Top Keywords
            </dt>
            <div class="w-70 h-[20%] overflow-y-auto p-4 bg-gray-100 rounded border border-gray-300">
              {ner.map((item) => {
                return (
                  <span>
                    {" "}
                    {item[1] != "O" ? (
                      <span className="bg-blue-200 rounded w-6 p-1 mr-1 ml-1">
                        {item[0]} {item[1]}
                      </span>
                    ) : null}{" "}
                  </span>
                ); //{item[1]=="O"?<div></div>:""}
              })}
            </div>

            <dt class="text-lg font-medium leading-6 text-gray-700 mt-4">
              General Sentiment
            </dt>
            <div class="w-70 h-[5%] overflow-y-auto  pb-2 pt-1 px-2 bg-gray-100 rounded border border-gray-300">
              {sentiment}
            </div>

            <dt class="text-lg font-medium leading-6 text-gray-700 mt-4">
              Gender Identified
            </dt>
            <div class="w-70 h-[5%] overflow-y-auto  pb-2 pt-1 px-2 bg-gray-100 rounded border border-gray-300">
              {gender}
            </div>
          </div>
        </div>

        {/* 
        <div class="grid grid-cols-2 gap-4 mt-2">
          <div class="p-1">

            <div className='mt-2'>
              <div class="px-4 sm:px-0">
                <MicIcon className="h-5 w-5" />
                <h3 class="text-base font-semibold leading-7 text-gray-900">Speaker 1</h3>
              </div>
              <div class="mt-6 border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Full Transcription:</dt>
                    <dd class="h-[250px] overflow-auto mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{transcription}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Sentiment Analysis</dt>
                  </div>
                  <div className="h-[250px] overflow-auto">
                    {
                      transcriptionSentences.map((item) => {
                        return <div className="m-2"> <span className={`${item.sentiment == 'negative' ? 'bg-red-200 rounded w-6 p-1 mr-1 ml-1' : item.sentiment == 'positive' ? 'bg-green-200 rounded w-6 p-1 mr-1 ml-1' : 'bg-blue-200 rounded w-6 p-1 mr-1 ml-1'}`}>{item.sentiment} - {item.sentence}</span> </div>
                      })
                    }
                  </div>

                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Named Entity Recognition</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 h-[250px] overflow-auto">
                      {
                        ner.map((item) => {
                          return <span> {item[1] != "O" ? <span className='bg-blue-200 rounded w-6 p-1 mr-1 ml-1'>{item[0]} {item[1]}</span> : null} </span>   //{item[1]=="O"?<div></div>:""} 
                        })
                      }

                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div class="p-1">

            <div className='mt-2'>
              <div class="px-4 sm:px-0">
                <MicIcon className="h-5 w-5" />
                <h3 class="text-base font-semibold leading-7 text-gray-900">Speaker 2</h3>
              </div>
              <div class="mt-6 border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Full Transcription:</dt>
                    <dd class="h-[250px] overflow-auto mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{transcription2}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Sentiment Analysis</dt>
                  </div>
                  <div className="h-[250px] overflow-auto">
                    {
                      transcriptionSentences2.map((item) => {
                        return <div className="m-2"> <span className={`${item.sentiment== 'negative' ? 'bg-red-200 rounded w-6 p-1 mr-1 ml-1' : item.sentiment == 'positive' ? 'bg-green-200 rounded w-6 p-1 mr-1 ml-1' : 'bg-blue-200 rounded w-6 p-1 mr-1 ml-1'}`}>{item.sentiment} - {item.sentence}</span> </div>
                      })
                    }
                  </div>

                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Named Entity Recognition</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 h-[250px] overflow-auto">
                      {
                        ner2.map((item) => {
                          return <span> {item[1] != "O" ? <span className='bg-blue-200 rounded w-6 p-1 mr-1 ml-1'>{item[0]} {item[1]}</span> : null} </span>   //{item[1]=="O"?<div></div>:""} 
                        })
                      }

                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

        </div> */}
      </div>
    </Layout>
  );
}

export default AudioTranscription;
