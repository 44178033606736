import { useState } from "react";
import Layout from "../components/Layout";

export default function Settings() {
  const [navigation, setnavigation] = useState([
    { name: "Change/ view profile data", current: true },
    { name: "Manage members (admins)", current: false },
  ]);

  const activeItem = navigation.filter((item) => item.current === true)[0];

  const handleItemClick = (clickedName) => {
    setnavigation((prevNavigation) =>
      prevNavigation.map((item) => ({
        ...item,
        current: item.name === clickedName,
      }))
    );
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Layout>
      <div className="min-h-[90vh] bg-[#FFFFFF] rounded-xl border border-[#E5EAEF] lg:flex">
        <h1 className="sr-only">General Settings</h1>

        <aside className="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-72 lg:flex-none lg:border-r lg:border-b-0 ">
          <nav className="flex-none px-0 lg:px-4 pb-4 lg:pb-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-4 whitespace-nowrap lg:flex-col mt-10 mx-5 lg:mx-0"
            >
              {navigation.map((item, index) => (
                <li key={item.name}>
                  <div
                    onClick={() => handleItemClick(item.name)}
                    className={classNames(
                      item.current
                        ? "bg-secondary text-white"
                        : "text-primary bg-[#F4F7FE]",
                      "group flex gap-x-3 rounded-xl py-3 px-4 text-base leading-6 font-medium cursor-pointer"
                    )}
                  >
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className=" sm:px-6 lg:flex-auto lg:px-0">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {activeItem.name === "Change/ view profile data" ? (
              <div className="py-7 px-4 lg:px-10 ">
                <div className="flex flex-col lg:flex-row lg:justify-between">
                  <img
                    src="/"
                    className=" h-40 w-40 rounded-2xl mx-auto lg:mx-0"
                  />

                  <div className="lg:mt-auto  mt-4 space-x-2">
                    <button className="p-2 px-5 border border-secondary bg-secondary text-white rounded-xl font-medium">
                      Edit Member
                    </button>
                    <button className="p-2 px-3 border border-[#ED544E] bg-[#ED544E] text-white rounded-xl font-medium">
                      Remove Member
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4 lg:my-10">
                  <div className="space-y-1">
                    <label className="flex-none my-auto text-base text-primary">
                      Full Name
                    </label>
                    <input
                      className="w-full border border-gray-200 py-3 px-3 outline-none text-sm rounded-xl"
                      placeholder="Enter full Name here"
                    />
                  </div>

                  <div className="space-y-1">
                    <label className="flex-none my-auto text-base text-primary">
                      Phone Number
                    </label>
                    <input
                      className="w-full border border-gray-200 py-3 px-3 outline-none text-sm rounded-xl"
                      placeholder="+960 00 0000 000"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
                  <div className="space-y-1">
                    <label className="flex-none my-auto text-base text-primary">
                      Email
                    </label>
                    <input
                      className="w-full border border-gray-200 py-3 px-3 outline-none text-sm rounded-xl"
                      placeholder="Enter email here"
                    />
                  </div>

                  <div className="space-y-1">
                    <label className="flex-none my-auto text-base text-primary">
                      Password
                    </label>
                    <input
                      className="w-full border border-gray-200 py-3 px-3 outline-none text-sm rounded-xl"
                      placeholder="*************"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="px-5">
                <Table
                  headers={["Name", "Position", "Action"]}
                  data={[
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                    {
                      name: "Ahmed Ali",
                      position: "Position here",
                    },
                  ]}
                  handleItemClick={handleItemClick}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </Layout>
  );
}

function Table({ headers, data, handleItemClick }) {
  return (
    <div className="flow-root ">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden   rounded-lg">
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-white">
                <tr>
                  {headers.map((header) => {
                    return (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-md text-secondary font-normal"
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item) => (
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div className="flex space-x-2 ">
                        <img
                          src="/"
                          className="h-8 w-8 rounded-full"
                        />
                        <span className="my-auto">{item.name}</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-400">
                      {item.position}
                    </td>
                    <td
                      onClick={() => {
                        handleItemClick("Change/ view profile data");
                      }}
                      className="whitespace-nowrap px-3 py-4 text-sm cursor-pointer"
                    >
                      Edit/Remove
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
