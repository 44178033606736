import { useState } from "react";
import moment from "moment";
import BarChart from "../components/charts/BarChart";
import Layout from "../components/Layout";

import { ReactComponent as AudioUploaded } from "../assets/icons/analytics/AudioUploaded.svg";
import { ReactComponent as TotalSatisfiedCalls } from "../assets/icons/analytics/TotalSatisfiedCalls.svg";
import { ReactComponent as TotalIncomeCalls } from "../assets/icons/analytics/TotalIncomeCalls.svg";
import { ReactComponent as TotalAngryCalls } from "../assets/icons/analytics/TotalAngryCalls.svg";
import { ReactComponent as Increment } from "../assets/icons/analytics/Increment.svg";
import { ReactComponent as Decrement } from "../assets/icons/analytics/Decrement.svg";

export default function Analytics() {
  const [records, setRecords] = useState([
    {
      _id: 0,
      keywords: "وزارة، الرياضة، خدمات",
      sentiment: "Happy 😀",
      duration: "2m 12s",
      date: "2023-01-01",
    },
    {
      _id: 0,
      keywords: "وزارة، التجارة المالية",
      sentiment: "Angry 😡",
      duration: "2m 12s",
      date: "2023-01-01",
    },
    {
      _id: 0,
      keywords: "وزارة، الرياضة، خدمات",
      sentiment: "Happy 😀",
      duration: "2m 12s",
      date: "2023-01-01",
    },
    {
      _id: 0,
      keywords: "وزارة، الرياضة، خدمات",
      sentiment: "Happy 😡",
      duration: "2m 12s",
      date: "2023-01-01",
    },
  ]);
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        data: labels.map(() => Math.floor(Math.random() * (600 - 50 + 1)) + 50),
        backgroundColor: "rgba(52, 202, 165, 0.5)",
        hoverBackgroundColor: "rgba(52, 202, 165, 1)",
        barBorderRadius: 100,
      },
    ],
  };

  return (
    <Layout>
      <div className="min-h-[90vh]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <div className="bg-white p-7 rounded-xl">
              <h1 className="text-lg text-[#898989] mb-5">Satisfaction Rate</h1>
              <BarChart data={data} />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            <div>
              <div className="bg-white p-4 px-7 rounded-xl space-y-4">
                <AudioUploaded />
                <h1 className="text-xl text-[#898989]">Audio Uploaded</h1>
                <h4 className="text-3xl font-medium text-primary">420</h4>
                <div className="flex justify-between">
                  <div className="bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <Increment
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                    23,5%
                  </div>
                  <div className="text-[#606060]">vs. previous month</div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white p-4 px-7 rounded-xl space-y-4">
                <TotalSatisfiedCalls />
                <h1 className="text-xl text-[#898989]">
                  Total Satisfied Calls
                </h1>
                <h4 className="text-3xl font-medium text-primary">650</h4>
                <div className="flex justify-between">
                  <div className="bg-red-100 text-red-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <Decrement
                      className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                    23,5%
                  </div>
                  <div className="text-[#606060]">vs. previous month</div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white p-4 px-7 rounded-xl space-y-4">
                <TotalAngryCalls />
                <h1 className="text-xl text-[#898989]">Total Angry Calls</h1>
                <h4 className="text-3xl font-medium text-primary">120</h4>
                <div className="flex justify-between">
                  <div className="bg-red-100 text-red-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <Decrement
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                    23,5%
                  </div>
                  <div className="text-[#606060]">vs. previous month</div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white p-4 px-7 rounded-xl space-y-4">
                <TotalIncomeCalls />
                <h1 className="text-xl text-[#898989]">Total Income Calls</h1>
                <h4 className="text-3xl font-medium text-primary">900</h4>
                <div className="flex justify-between">
                  <div className="bg-green-100 text-green-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                    <Increment
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                    23,5%
                  </div>
                  <div className="text-[#606060]">vs. previous month</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <button class="flex rounded-full bg-secondary px-7 py-2 text-sm font-semibold text-white my-3 ml-auto">
            Export Report
          </button>
          <Table
            headers={["Sentiment", "Keywords", "Duration", "Date"]}
            data={records}
          />
        </div>
      </div>
    </Layout>
  );
}

function Table({ headers, data }) {
  return (
    <div className="flow-root px-4 lg:px-0">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden  ring-1 ring-black ring-opacity-5 rounded-lg">
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-white">
                <tr>
                  {headers.map((header) => {
                    return (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-md font-medium text-[#9CA4AB]"
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item, index) => (
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {item.sentiment}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {item.keywords}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {item.duration}m
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {moment(item.date).format("MMM D, YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

//====================================================================
//====================================================================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//====================================================================
//====================================================================

// import Layout from "../components/Layout";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import ReactWordcloud from "react-wordcloud";
// import { Resizable } from "re-resizable";
// import { Chart } from "react-google-charts";

// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";

// export default function Analytics() {
//   const navigate = useNavigate();
// const [records, setRecords] = useState([
//   {
//     _id: 0,
//     keywords: "وزارة، الرياضة، خدمات",
//     sentiment: "Happy 😀",
//     duration: "2m 12s",
//     date: "2023-01-01",
//   },
//   {
//     _id: 0,
//     keywords: "وزارة، التجارة المالية",
//     sentiment: "Angry 😡",
//     duration: "2m 12s",
//     date: "2023-01-01",
//   },
//   {
//     _id: 0,
//     keywords: "وزارة، الرياضة، خدمات",
//     sentiment: "Happy 😀",
//     duration: "2m 12s",
//     date: "2023-01-01",
//   },
//   {
//     _id: 0,
//     keywords: "وزارة، الرياضة، خدمات",
//     sentiment: "Happy 😡",
//     duration: "2m 12s",
//     date: "2023-01-01",
//   },
// ]);
// const words = [
//   {
//     text: "told",
//     value: 64,
//   },
//   {
//     text: "mistake",
//     value: 11,
//   },
//   {
//     text: "thought",
//     value: 16,
//   },
//   {
//     text: "bad",
//     value: 17,
//   },
//   {
//     text: "correct",
//     value: 10,
//   },
//   {
//     text: "day",
//     value: 54,
//   },
//   {
//     text: "prescription",
//     value: 12,
//   },
//   {
//     text: "time",
//     value: 77,
//   },
//   {
//     text: "thing",
//     value: 45,
//   },
//   {
//     text: "left",
//     value: 19,
//   },
//   {
//     text: "pay",
//     value: 13,
//   },
//   {
//     text: "people",
//     value: 32,
//   },
//   {
//     text: "month",
//     value: 22,
//   },
//   {
//     text: "again",
//     value: 35,
//   },
//   {
//     text: "review",
//     value: 24,
//   },
//   {
//     text: "call",
//     value: 38,
//   },
//   {
//     text: "doctor",
//     value: 70,
//   },
//   {
//     text: "asked",
//     value: 26,
//   },
//   {
//     text: "finally",
//     value: 14,
//   },
//   {
//     text: "insurance",
//     value: 29,
//   },
//   {
//     text: "week",
//     value: 41,
//   },
//   {
//     text: "called",
//     value: 49,
//   },
//   {
//     text: "problem",
//     value: 20,
//   },
//   {
//     text: "going",
//     value: 59,
//   },
//   {
//     text: "help",
//     value: 49,
//   },
//   {
//     text: "felt",
//     value: 45,
//   },
//   {
//     text: "discomfort",
//     value: 11,
//   },
//   {
//     text: "lower",
//     value: 22,
//   },
//   {
//     text: "severe",
//     value: 12,
//   },
//   {
//     text: "free",
//     value: 38,
//   },
//   {
//     text: "better",
//     value: 54,
//   },
//   {
//     text: "muscle",
//     value: 14,
//   },
//   {
//     text: "neck",
//     value: 41,
//   },
//   {
//     text: "root",
//     value: 24,
//   },
//   {
//     text: "adjustment",
//     value: 16,
//   },
//   {
//     text: "therapy",
//     value: 29,
//   },
//   {
//     text: "injury",
//     value: 20,
//   },
//   {
//     text: "excruciating",
//     value: 10,
//   },
//   {
//     text: "chronic",
//     value: 13,
//   },
//   {
//     text: "chiropractor",
//     value: 35,
//   },
//   {
//     text: "treatment",
//     value: 59,
//   },
//   {
//     text: "tooth",
//     value: 32,
//   },
//   {
//     text: "chiropractic",
//     value: 17,
//   },
//   {
//     text: "dr",
//     value: 77,
//   },
//   {
//     text: "relief",
//     value: 19,
//   },
//   {
//     text: "shoulder",
//     value: 26,
//   },
//   {
//     text: "nurse",
//     value: 17,
//   },
//   {
//     text: "room",
//     value: 22,
//   },
//   {
//     text: "hour",
//     value: 35,
//   },
//   {
//     text: "wait",
//     value: 38,
//   },
//   {
//     text: "hospital",
//     value: 11,
//   },
//   {
//     text: "eye",
//     value: 13,
//   },
//   {
//     text: "test",
//     value: 10,
//   },
//   {
//     text: "appointment",
//     value: 49,
//   },
//   {
//     text: "medical",
//     value: 19,
//   },
//   {
//     text: "question",
//     value: 20,
//   },
//   {
//     text: "office",
//     value: 64,
//   },
//   {
//     text: "care",
//     value: 54,
//   },
//   {
//     text: "minute",
//     value: 29,
//   },
//   {
//     text: "waiting",
//     value: 16,
//   },
//   {
//     text: "patient",
//     value: 59,
//   },
//   {
//     text: "health",
//     value: 49,
//   },
//   {
//     text: "alternative",
//     value: 24,
//   },
//   {
//     text: "holistic",
//     value: 19,
//   },
//   {
//     text: "traditional",
//     value: 20,
//   },
//   {
//     text: "symptom",
//     value: 29,
//   },
//   {
//     text: "internal",
//     value: 17,
//   },
//   {
//     text: "prescribed",
//     value: 26,
//   },
//   {
//     text: "acupuncturist",
//     value: 16,
//   },
//   {
//     text: "pain",
//     value: 64,
//   },
//   {
//     text: "integrative",
//     value: 10,
//   },
//   {
//     text: "herb",
//     value: 13,
//   },
//   {
//     text: "sport",
//     value: 22,
//   },
//   {
//     text: "physician",
//     value: 41,
//   },
//   {
//     text: "herbal",
//     value: 11,
//   },
//   {
//     text: "eastern",
//     value: 12,
//   },
//   {
//     text: "chinese",
//     value: 32,
//   },
//   {
//     text: "acupuncture",
//     value: 45,
//   },
//   {
//     text: "prescribe",
//     value: 14,
//   },
//   {
//     text: "medication",
//     value: 38,
//   },
//   {
//     text: "western",
//     value: 35,
//   },
//   {
//     text: "sure",
//     value: 38,
//   },
//   {
//     text: "work",
//     value: 64,
//   },
//   {
//     text: "smile",
//     value: 17,
//   },
//   {
//     text: "teeth",
//     value: 26,
//   },
//   {
//     text: "pair",
//     value: 11,
//   },
//   {
//     text: "wanted",
//     value: 20,
//   },
//   {
//     text: "frame",
//     value: 13,
//   },
//   {
//     text: "lasik",
//     value: 10,
//   },
//   {
//     text: "amazing",
//     value: 41,
//   },
//   {
//     text: "fit",
//     value: 14,
//   },
//   {
//     text: "happy",
//     value: 22,
//   },
//   {
//     text: "feel",
//     value: 49,
//   },
//   {
//     text: "glasse",
//     value: 19,
//   },
//   {
//     text: "vision",
//     value: 12,
//   },
//   {
//     text: "pressure",
//     value: 16,
//   },
//   {
//     text: "find",
//     value: 29,
//   },
//   {
//     text: "experience",
//     value: 59,
//   },
//   {
//     text: "year",
//     value: 70,
//   },
//   {
//     text: "massage",
//     value: 35,
//   },
//   {
//     text: "best",
//     value: 54,
//   },
//   {
//     text: "mouth",
//     value: 20,
//   },
//   {
//     text: "staff",
//     value: 64,
//   },
//   {
//     text: "gum",
//     value: 10,
//   },
//   {
//     text: "chair",
//     value: 12,
//   },
//   {
//     text: "ray",
//     value: 22,
//   },
//   {
//     text: "dentistry",
//     value: 11,
//   },
//   {
//     text: "canal",
//     value: 13,
//   },
//   {
//     text: "procedure",
//     value: 32,
//   },
//   {
//     text: "filling",
//     value: 26,
//   },
//   {
//     text: "gentle",
//     value: 19,
//   },
//   {
//     text: "cavity",
//     value: 17,
//   },
//   {
//     text: "crown",
//     value: 14,
//   },
//   {
//     text: "cleaning",
//     value: 38,
//   },
//   {
//     text: "hygienist",
//     value: 24,
//   },
//   {
//     text: "dental",
//     value: 59,
//   },
//   {
//     text: "charge",
//     value: 24,
//   },
//   {
//     text: "cost",
//     value: 29,
//   },
//   {
//     text: "charged",
//     value: 13,
//   },
//   {
//     text: "spent",
//     value: 17,
//   },
//   {
//     text: "paying",
//     value: 14,
//   },
//   {
//     text: "pocket",
//     value: 12,
//   },
//   {
//     text: "dollar",
//     value: 11,
//   },
//   {
//     text: "business",
//     value: 32,
//   },
//   {
//     text: "refund",
//     value: 10,
//   },

//   {
//     text: "paying1",
//     value: 114,
//   },
//   {
//     text: "pocket1",
//     value: 112,
//   },
//   {
//     text: "dolla1r",
//     value: 111,
//   },
//   {
//     text: "busin1ess",
//     value: 132,
//   },
//   {
//     text: "re1und",
//     value: 110,
//   },
// ];
// const resizeStyle = {
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   border: "solid 1px #ddd",
//   background: "#ffffff",
// };

//   const data = [
//     ["Task", "Hours per Day"],
//     ["Positive", 11],
//     ["Negative", 2],
//     ["Neutral", 2],
//   ];

//   const options = {
//     // title: "My Daily Activities",
//     // width: 500,
//     // height: 340,
//     title: "Calls Sentiment Analysis",
//     colors: ["#31a531", "#d73e37", "#ffa500"],
//   };

//   return (
//     <Layout>
//       <div>
//         <div class="max-w-full mx-4 py-6 sm:mx-auto">
//           <div class="sm:flex sm:space-x-4">
//             <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
//               <div class="bg-white p-5">
//                 <div class="sm:flex sm:items-start">
//                   <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
//                     <h3 class="text-sm leading-6 font-medium text-gray-600">
//                       Total Audios Uploaded
//                     </h3>
//                     <p class="text-3xl font-bold text-black">120k</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
//               <div class="bg-white p-5">
//                 <div class="sm:flex sm:items-start">
//                   <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
//                     <h3 class="text-sm leading-6 font-medium text-gray-600">
//                       Total Positive Calls 😀
//                     </h3>
//                     <p class="text-3xl font-bold text-black">120k</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
//               <div class="bg-white p-5">
//                 <div class="sm:flex sm:items-start">
//                   <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
//                     <h3 class="text-sm leading-6 font-medium text-gray-600">
//                       Total Negative Calls 😡
//                     </h3>
//                     <p class="text-3xl font-bold text-black">120k</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div class="relative overflow-x-auto flex">
//           {/* <ReactWordcloud class="bg-white w-full text-sm text-left text-gray-500" words={words} /> */}
// <div>
//   <Resizable
//     defaultSize={{
//       width: 600,
//       height: 400,
//     }}
//     style={resizeStyle}
//   >
//     <ReactWordcloud words={words} />
//   </Resizable>
// </div>

//           <Chart
//             chartType="PieChart"
//             data={data}
//             options={options}
//             width={"100%"}
//             height={"450px"}
//           />
//         </div>

// <button className="bg-blue-300 p-1 rounded mb-2">Export Report</button>
// <table class="w-full text-sm text-left text-gray-500">
//   <thead class="text-xs text-gray-700 uppercase bg-gray-50">
//     <tr>
//       <th scope="col" class="px-6 py-3">
//         Sentiment
//       </th>
//       <th scope="col" class="px-6 py-3">
//         Duration
//       </th>
//       <th scope="col" class="px-6 py-3">
//         Keywords
//       </th>
//       <th scope="col" class="px-6 py-3">
//         Date
//       </th>
//     </tr>
//   </thead>
//   <tbody>
//     {records.map((item) => (
//       <tr class="bg-white border-b  hover:bg-gray-50">
//         <td class="px-6 py-4">
//           <div class="flex items-center">{item.sentiment}</div>
//         </td>
//         <td class="px-6 py-4">
//           <div class="flex items-center">{item.duration}</div>
//         </td>
//         <td class="px-6 py-4">
//           <div class="flex items-center">{item.keywords}</div>
//         </td>
//         <td class="px-6 py-4">
//           <a class="flex items-center ">{item.date}</a>
//         </td>
//       </tr>
//     ))}
//   </tbody>
// </table>
//       </div>
//     </Layout>
//   );
// }
