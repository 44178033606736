import publicInstance from "../../utils/publicInstance";
import { API_GET_AUDIOS, API_CREATE_AUDIOS } from "../../constants/apis/Audios";

const getAudiosAPI = async () => {
  return await publicInstance(API_GET_AUDIOS)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

const getAudioByIdAPI = async (id) => {
  return await publicInstance({
    ...API_GET_AUDIOS,
    url: `${API_GET_AUDIOS.url}/${id}`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

const createAudioAPI = async (data) => {
  const config = {
    ...API_CREATE_AUDIOS,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export { getAudiosAPI, getAudioByIdAPI, createAudioAPI };
