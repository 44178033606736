import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteModal from "../components/DeleteModal";
import EditVideoModal from "../components/EditVideoModal";

function UploadAudio() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  // const [videos, setVideos] = useState(
  //     {
  //         "pending": [
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "N/A",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 1,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "N/A",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 2,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "N/A",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             }

  //         ],
  //         "Quality Control": [
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Pets",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Entertainment",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Viral Event",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Pets",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Kids",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             }

  //         ],
  //         "On Social Media": [
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Kids",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //             {
  //                 _id: 0,
  //                 user: "Zaid Sallam",
  //                 video: "video22.mp4",
  //                 category: "Kids",
  //                 date: "2023-10-01 22:00pm GMT+3"
  //             },
  //         ]
  //     }
  // )

  function deleteItem() {
    alert("this video has been deleted= " + selectedVideoId);
  }
  function editItem() {
    alert("this video has been modified= " + selectedVideoId);
  }

  return (
    <Layout>
      <div class="pl-4 pr-4 sm:ml-64 ">
        <div class="col-span-full mt-12">
          <label for="cover-photo" class="block text-lg  text-gray-900">
            Upload your Audio File{" "}
          </label>
          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div class="text-center">
              <svg
                class="mx-auto h-12 w-12 text-gray-300"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <div class="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  for="file-upload"
                  class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload File</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    class="sr-only"
                  />
                </label>
                <p class="pl-1">or drag and drop</p>
              </div>
              <p class="text-xs leading-5 text-gray-600">
                .wav / .mp3 up to 100MB
              </p>
            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={(e) => navigate("/AudioAnalysis")}
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Upload
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default UploadAudio;
