import { Routes, Route } from "react-router-dom";
import "./App.css";

import Login from "./pages/Login";
import Analytics from "./pages/Analytics";
import Audio from "./pages/Audio";
import AudoAnalysis from "./pages/AudoAnalysis";
import STT from "./pages/STT";
import Calls from "./pages/Calls";
import Uploader from "./pages/Uploader";
import Copilot from "./pages/Copilot";
import Config from "./pages/Config";

import CallDetails from "./pages/CallDetails";
import Settings from "./pages/Settings";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/uploader" element={<Uploader />} />
        <Route path="/copilot" element={<Copilot />} />
        <Route path="/config" element={<Config />} />
        
        <Route path="/calls" element={<Calls />} />
        <Route path="/callDetails" element={<CallDetails />} />
        <Route path="/settings" element={<Settings />} />

        <Route path="/audio" element={<Audio />} />
        <Route path="/AudioAnalysis" element={<AudoAnalysis />} />
        <Route path="/stt" element={<STT />} />

        {/* <Route path="/upload" element={<UploadForm />} ></Route> */}
        {/* <Route path="/profile" element={<Profile />} ></Route> */}
        {/* <Route path="/wallet" element={<Wallet />} ></Route> */}
        {/* <Route path="/login" element={<Login />} ></Route> */}
        {/* <Route path="/terms" element={<Terms />} ></Route> */}
      </Routes>
    </div>
  );
}

export default App;
