import { useNavigate } from "react-router-dom";
import { ReactComponent as LabeahLab } from "../assets/icons/LabeahLab.svg";

export default function Login() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 md:flex-none md:px-20 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto md:w-[25rem] lg:w-[35rem] ">
            <div>
              <LabeahLab />

              <h2 className="mt-8 text-3xl font-medium leading-9 tracking-tight text-primary">
                Sign in
              </h2>
              <p className="mt-2 text-base leading-6 text-primaryLight">
                Enter your email and password to sign in!
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-base font-regular leading-6 text-primary"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        placeholder="mail@company.com"
                        className="block w-full rounded-2xl py-3 px-4 border "
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-base font-regular leading-6 text-primary"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        placeholder="**************"
                        className="block w-full rounded-2xl py-3 px-4 border "
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-primary text-primary focus:ring-primary"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-primary"
                      >
                        Keep me logged in
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a href="#" className="font-regular text-primary">
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      onClick={(e) => {
                        navigate("/analytics");
                      }}
                      className="flex w-full justify-center rounded-md bg-secondary py-4 text-lg font-medium leading-6 text-white"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 md:block lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover rounded-l-[5rem]	"
            src="/LoginBg.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
