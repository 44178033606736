import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getAudiosAPI } from "../services/apis/Audios";

import Layout from "../components/Layout";
import { ReactComponent as Download } from "../assets/icons/calls/Download.svg";

export default function Calls() {
  const [records, setRecords] = useState([
    // {
    //   img: "https://thispersondoesnotexist.com/",
    //   name: "Ahmed Ali",
    //   date: "Jan 01,2024",
    //   duration: "2.6",
    //   status: "Completed",
    //   sentiment: "Positive",
    //   alert: "N/A",
    //   actions: "Open",
    //   audioLink: "link_url",
    // },
    // {
    //   img: "https://thispersondoesnotexist.com/",
    //   name: "Saleh Mohammed",
    //   date: "Jan 01,2024",
    //   duration: "3",
    //   status: "Completed",
    //   sentiment: "Positive",
    //   alert: "N/A",
    //   actions: "Open",
    //   audioLink: "link_url",
    // },
    // {
    //   img: "https://thispersondoesnotexist.com/",
    //   name: "Saad Abd-Allah",
    //   date: "Jan 01,2024",
    //   duration: "6",
    //   status: "Completed",
    //   sentiment: "Negative",
    //   alert: "Action Need",
    //   actions: "Open",
    //   audioLink: "link_url",
    // },
    // {
    //   img: "https://thispersondoesnotexist.com/",
    //   name: "Saleh Mohammed",
    //   date: "Jan 01,2024",
    //   duration: "3",
    //   status: "Processing",
    //   sentiment: "Positive",
    //   alert: "N/A",
    //   actions: "Open",
    //   audioLink: "link_url",
    // },
    // {
    //   img: "https://thispersondoesnotexist.com/",
    //   name: "Saad Abd-Allah",
    //   date: "Jan 01,2024",
    //   duration: "6",
    //   status: "Completed",
    //   sentiment: "Negative",
    //   alert: "Action Need",
    //   actions: "Open",
    //   audioLink: "link_url",
    // },
  ]);

  async function getAudios() {
    await getAudiosAPI()
      .then((res) => {
        setRecords(res.audios);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getAudios();
  }, []);

  return (
    <Layout>
      <div className="min-h-[90vh]">
        <div className="bg-[#FFFFFF] rounded-xl border border-[#E5EAEF] p-5 ">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5 max-w-3xl">
            <div className="flex space-x-4">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                All Records
              </label>
              <select
                id="country"
                name="country"
                className="w-full rounded-md border border-[#9CA4AB] py-1.5 text-[#9CA4AB] outline-none"
              >
                <option>All</option>
                <option>None</option>
              </select>
            </div>

            <div className="flex flex-col md:flex-row lg:flex-row space-y-3 md:space-y-0 md:space-x-3  lg:space-y-0 lg:space-x-3 col-span-2">
              <div className="flex space-x-4">
                <label className="flex-none my-auto text-base text-[#9CA4AB]">
                  From Date
                </label>
                <input
                  type="date"
                  id="datePicker"
                  name="datePicker"
                  className="border border-[#9CA4AB] px-2 rounded-md appearance-none text-[#9CA4AB]"
                />
              </div>
              <div className="flex space-x-4">
                <label className="flex-none my-auto text-base text-[#9CA4AB]">
                  To Date
                </label>
                <input
                  type="date"
                  id="datePicker"
                  name="datePicker"
                  className="border border-[#9CA4AB] px-2 rounded-md appearance-none text-[#9CA4AB]"
                />
              </div>
            </div>

            <fieldset className="flex space-x-3 ">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Sentiment Type
              </label>
              <div className="flex items-center gap-x-3">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-0 outline-none accent-red-300 md:accent-red-500"
                />
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-0 outline-none accent-blue-300 md:accent-blue-500"
                />
              </div>
            </fieldset>

            <div className="flex space-x-6 col-span-2">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                By Agent
              </label>
              <select
                id="country"
                name="country"
                className="w-40 rounded-md border border-[#9CA4AB] py-1.5 text-[#9CA4AB] outline-none"
              >
                <option>All</option>
                <option>None</option>
              </select>
            </div>

            <fieldset className="flex space-x-3 ">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                By Assistant
              </label>
              <div className="flex items-center gap-x-3">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-0 outline-none accent-red-300 md:accent-red-500"
                />
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-0 outline-none accent-blue-300 md:accent-blue-500"
                />
              </div>
            </fieldset>

            <div className="flex space-x-4 col-span-2">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                All Records
              </label>
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                className="my-auto accent-secondaryDark"
                style={{
                  width: "100%",
                  height: "10px",
                  borderRadius: "5px",
                  backgroundColor: "lightgray",
                  appearance: "none",
                }}
              />
            </div>
          </div>

          <div className="flex space-x-3 mt-5 md:mt-5 lg:mt-0">
            <button className="ml-auto p-1 px-3 border border-primary text-primary rounded-xl font-medium">
              Reset Filter
            </button>
            <button className="p-1 px-3 border border-secondary bg-secondary text-white rounded-xl font-medium">
              Apply Filter
            </button>
          </div>
        </div>

        <div className="mt-8">
          <Table
            headers={[
              // "Name",
              "Duration",
              "Status",
              // "Sentiment",
              // "Alert",
              "Date",
              "Actions",
              "Audio Link",
            ]}
            data={records}
          />
        </div>
      </div>
    </Layout>
  );
}

function Table({ headers, data }) {
  const navigate = useNavigate();

  function downloadFile(audioLink) {
    const fileUrl = audioLink;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  }

  return (
    <div className="flow-root px-4 lg:px-0">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden  ring-1 ring-black ring-opacity-5 rounded-lg">
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-white">
                <tr>
                  {headers.map((header) => {
                    return (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-md font-medium text-[#9CA4AB]"
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item, index) => (
                  <tr>
                    {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div className="flex space-x-2 ">
                        <img src={item.img} className="h-8 w-8 rounded-full" />
                        <span className="my-auto">{item.name}</span>
                      </div>
                    </td> */}

                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {item.duration}m
                    </td>
                    <td
                      className={`${
                        item.status === "COMPLETED"
                          ? "text-secondaryDark"
                          : "text-primary"
                      } whitespace-nowrap px-3 py-4 text-sm`}
                    >
                      {item.status}
                    </td>
                    {/* <td
                      className={`${
                        item.sentiment === "Positive"
                          ? "text-secondaryDark"
                          : "text-[#ED544E]"
                      } whitespace-nowrap px-3 py-4 text-sm`}
                    >
                      {item.sentiment}
                    </td> */}
                    {/* <td
                      className={`${
                        item.alert === "N/A" ? "" : "text-[#ED544E]"
                      } whitespace-nowrap px-3 py-4 text-sm`}
                    >
                      {item.alert}
                    </td> */}
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {moment(item.created).format("DD/MM/YYYY")}
                    </td>
                    <td
                      onClick={() =>
                        navigate("/callDetails", {
                          state: {
                            id: item._id,
                          },
                        })
                      }
                      className="whitespace-nowrap px-3 py-4 text-sm font-bold cursor-pointer"
                    >
                      Open
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <div
                        className="flex space-x-2 cursor-pointer"
                        onClick={() => downloadFile(item.link)}
                      >
                        <Download className="h-5 w-5" />
                        <span className="my-auto cursor-pointer">Download</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

//====================================================================
//====================================================================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//==============================OLD CODE==============================
//====================================================================
//====================================================================

// import React, { useEffect, useState, useRef } from "react";
// import Layout from "../components/Layout";
// import { useNavigate } from "react-router-dom";
// import ReactWordcloud from "react-wordcloud";
// import { Resizable } from "re-resizable";
// import { Chart } from "react-google-charts";
// import FilterBox from "../components/FilterBox";
// import CallModal from "../components/CallModal";

// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
// import axios from "axios";

// function Analytics() {
//   const navigate = useNavigate();
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState({});
// const [records, setRecords] = useState([]);

// async function getAudios() {
//   try {
//     let response = await axios.get("http://localhost:5000/audios");
//     if (response.data) setRecords(response.data["audios"]);
//   } catch (err) {
//     console.log("err", err);
//   }
// }

// useEffect(() => {
//   getAudios();
// }, []);

//   return (
//     <>
//       <Layout>
//         <div class="pl-4 pr-4 sm:ml-64">
//           <div className="flex w-full">
//             <div className="w-4/12 p-2">
//               {/* This div takes up 30% of the width */}
//               <FilterBox />
//             </div>
//             <div className="w-full p-2">
//               {/* This div takes up the remaining 70% of the width */}
//               {/* <button onClick={((e)=>{navigate("/uploader")})} type="submit" class="text-white   bg-gray-400  focus:ring-4  font-medium rounded-lg text-sm  light:bg-gray-600  px-4 py-2 mt-4">Upload manual Record +</button> */}
//               <form className="my-10">
//                 <label
//                   for="default-search"
//                   class="mb-2 text-sm font-medium text-gray-900 sr-only light:text-white"
//                 >
//                   Search
//                 </label>
//                 <div class="relative">
//                   <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                     <svg
//                       class="w-4 h-4 text-gray-500 light:text-gray-400"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 20 20"
//                     >
//                       <path
//                         stroke="currentColor"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                         stroke-width="2"
//                         d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                       />
//                     </svg>
//                   </div>
//                   <input
//                     type="search"
//                     id="default-search"
//                     class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500  light:bg-gray-700  light:placeholder-gray-400 light:text-white  "
//                     placeholder="Agent name, sentiment, date..."
//                     required
//                   />
//                   <button
//                     type="submit"
//                     class="text-white absolute end-2.5 bottom-2.5 bg-primary  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800"
//                   >
//                     Search
//                   </button>
//                 </div>
//               </form>
//               {/* <button className="bg-blue-300 p-1 rounded mb-2">Export Report</button> */}
//               <table class="w-full text-sm text-left text-gray-500">
//                 <thead class="text-xs text-gray-700 uppercase bg-gray-50">
//                   <tr>
//                     <th scope="col" class="px-6 py-3">
//                       Date - Time
//                     </th>
//                     <th scope="col" class="px-6 py-3">
//                       Status
//                     </th>
//                     <th scope="col" class="px-6 py-3">
//                       Duration
//                     </th>
//                     <th scope="col" class="px-6 py-3">
//                       Audio Link
//                     </th>
//                     <th scope="col" class="px-6 py-3">
//                       Actions
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {records.map((item) => (
//                     <tr class="bg-white border-b  hover:bg-gray-50">
//                       <td class="px-6 py-4">
//                         <a class="flex items-center ">{item.created}</a>
//                       </td>
//                       <td class="px-6 py-4">
//                         <div
//                           class={`${
//                             item.status == "COMPLETED"
//                               ? "text-green-500"
//                               : item.status == "PROCESSING" ||
//                                 item.status == "QUEUED"
//                               ? "text-orange-500"
//                               : "text-primary"
//                           } flex items-center`}
//                         >
//                           {item.status}
//                         </div>
//                       </td>

//                       <td class="px-6 py-4">
//                         <div class="flex items-center">{item.duration}</div>
//                       </td>
//                       <td class="px-6 py-4">
//                         <div class="flex items-center">
//                           <a
//                             className="text-blue-800 underline"
//                             href={item.link}
//                           >
//                             Audio Link
//                           </a>
//                         </div>
//                       </td>
//                       <td class="px-6 py-4">
//                         <div class="flex items-center">
//                           <button
//                             onClick={(e) => {
//                               setModalOpen(true);
//                               setSelectedItem(item);
//                             }}
//                             className="text-primary p-1 rounded mb-2 font-bold"
//                           >
//                             Details
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </Layout>

//       <CallModal
//         isModalOpen={isModalOpen}
//         setModalOpen={setModalOpen}
//         selectedItem={selectedItem}
//       />
//     </>
//   );
// }

// export default Analytics;
