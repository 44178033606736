import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "axios";

import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { ReactComponent as UploadFile } from "../assets/icons/Uploader/UploadFile.svg";
import moment from "moment";

AWS.config.update({
  accessKeyId: "AKIA2O26TDY7DCNW5YX7",
  secretAccessKey: "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",
  region: "eu-north-1",
});

export default function Uploader() {
  const navigate = useNavigate();
  const [audios, setAudios] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(null);

  const handleFileChange = async (event) => {
    setShowLoader(true);

    const files = Array.from(event.target.files); // Handle multiple files
    const S3_BUCKET = "sampleaudios24";
    const s3 = new AWS.S3();

    let currentAudios = audios;

    for (const file of files) {
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
      };

      const audio = new Audio();
      let duration = "";
      audio.src = URL.createObjectURL(file);
      audio.onloadedmetadata = () => {
        duration = (audio.duration / 60).toFixed(2);
      };

      try {
        await s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            setUploadPercentage(parseInt((evt.loaded * 100) / evt.total) + "%");
          })
          .promise();

        currentAudios.push({
          date: new Date().toString(),
          link:
            "https://sampleaudios24.s3.eu-north-1.amazonaws.com/" + file.name,
          duration: duration,
        });
      } catch (err) {
        setUploadPercentage(null);
        setShowLoader(false);
        alert("Something went wrong!!");
      }
    }

    setAudios([...currentAudios]);
    setShowLoader(false);
    setUploadPercentage(null);
  };

  function removeAudio(e, audioIndex) {
    e.preventDefault();
    const newArray = [...audios];
    newArray.splice(audioIndex, 1);
    setAudios(newArray);
  }

  async function uploadAudios() {
    if (audios.length > 0) {
      let promises = [];
      audios.map((audio) => {
        promises.push(
          axios.post("https://analytics-api.labeah.ai/audios", {
            link: audio.link,
            duration: audio.duration,
          })
        );
      });
      try {
        let response = await Promise.all(promises);
        alert("All audios have been submitted successfully.");
        navigate("/calls");
      } catch (err) {
        alert("Error:", err);
      }
    } else {
      alert("No audios uploaded");
    }
  }

  return (
    <>
      {showLoader ? (
        <Loader open={showLoader} percentage={uploadPercentage} />
      ) : null}
      <Layout>
        <div className="min-h-[90vh]">
          <div className="bg-white border rounded-xl p-5">
            <h1 className="text-base text-[#898989] mb-4">
              Upload Audio Files
            </h1>

            <div className="flex justify-center rounded-lg border border-dashed border-[#E0E5F2] bg-[#FAFCFE] px-6 py-10">
              <div>
                <UploadFile className="mx-auto" />
                <div className="mt-4 flex text-lg my-3 leading-6 text-gray-600 text-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-semibold text-primary mx-auto"
                  >
                    <span>Upload Files</span>
                    <input
                      accept=".wav, .mp3"
                      onChange={handleFileChange}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      multiple // Allow multiple file selection
                    />
                  </label>
                </div>
                <p className="text-sm leading-5 text-[#8F9BBA] text-center">
                  MP3, WAV and Audio files are allowed
                </p>
              </div>
            </div>

            <div className="mt-10 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold text-primary rounded-full bg-[#D4D4D4] px-3 py-2"
              >
                Cancel
              </button>
              <button
                onClick={uploadAudios}
                type="submit"
                className="w-[200px] rounded-full bg-secondary px-3 py-2 text-sm font-semibold text-white"
              >
                Send to Processing
              </button>
            </div>
          </div>

          {audios.length > 0 ? (
            <Table
              headers={["Link", "Date", "Duration", ""]}
              data={audios}
              removeAudio={removeAudio}
            />
          ) : null}
        </div>
      </Layout>
    </>
  );
}

function Table({ headers, data, removeAudio }) {
  return (
    <div className="mt-8 flow-root px-4 lg:px-0">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden  ring-1 ring-black ring-opacity-5 rounded-lg">
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-white">
                <tr>
                  {headers.map((header) => (
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-md font-medium text-[#9CA4AB]"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {item.link}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {moment(item.date).format("MMM D, YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {item.duration}m
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div
                        onClick={(e) => removeAudio(e, index)}
                        className="text-red-600 hover:text-red-700 cursor-pointer"
                      >
                        remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
