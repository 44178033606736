import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import WaveComp from "../components/WaveComp";
import { useLocation } from "react-router-dom";
import { getAudioByIdAPI } from "../services/apis/Audios";

export default function CallDetails() {
  const location = useLocation();
  const { id } = location.state;
  const [audio, setaudio] = useState(null);

  async function getAudioById() {
    await getAudioByIdAPI(id)
      .then((res) => {
        setaudio(res.audio);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (id) getAudioById();
  }, [id]);

  return (
    <Layout>
      <div className="min-h-[90vh]">
        {audio ? (
          <div className="bg-[#FFFFFF] rounded-xl border border-[#E5EAEF] p-5 ">
            <WaveComp audioFile={audio.link} />
          </div>
        ) : null}

        <div className="bg-[#FFFFFF] rounded-xl border border-[#E5EAEF] p-5 mt-7">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Caller Name
              </label>
              <input
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                value="Mohammed Ahmad"
              />
            </div>
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Number
              </label>
              <input
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                value={+9961237652}
              />
            </div>
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Historic Calls
              </label>
              <input
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                value="3"
              />
            </div>
            <div>
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Gender
              </label>
              <div className="flex space-x-3">
                <div className="bg-secondary text-white p-3 px-6">Female</div>
                <div className="border border-primary text-primary p-3 px-4">
                  Male
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-1 my-8 h-[400px] overflow-y-auto  border-gray-300 border">
            <label className="flex-none my-auto text-base text-[#9CA4AB] m-2">
              Full Transcription
            </label>

            {
              audio && audio.messages ? audio.messages.map((item, index) => (
                <div key={index} className={`flex ${item.speaker.includes("Guest-1") ? 'justify-end m-2' : 'justify-start'} m-2`}>
                  <div className={`${item.speaker.includes("Guest-1") ? 'bg-blue-100' : 'bg-secondary text-white'} p-2 rounded max-w-xs`}>
                    {item.text} 
                  </div>
                </div>
              )) : null
            }



            {/* <textarea
              rows={14}
              value={audio?.full_transcriptions}
              className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
              placeholder="Type here..."
            /> */}
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Caller Summary
              </label>
              <textarea
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                value={audio?audio.llm_result?audio.llm_result.summarizing:null:null}
              />
            </div>
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Topic Classification
              </label>
              <textarea
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                value={audio?audio.llm_result?audio.llm_result.topic_classification:null:null}
              />
            </div>
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Keywords
              </label>
              <input
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                // value="الملك فهد،رقم الهوية،حجز موعد؟،باطنية"
                value={audio?audio.llm_result?audio.llm_result.top_keywords:null:null}
              />
            </div>
            <div className="space-y-1">
              <label className="flex-none my-auto text-base text-[#9CA4AB]">
                Sentiment Analysis (Overall)
              </label>
              <input
                className="w-full border border-[#E5E5E5] py-3 px-3 outline-none text-sm"
                placeholder="Type here..."
                // value="الملك فهد،رقم الهوية،حجز موعد؟،باطنية"
                value={audio?audio.llm_result?audio.llm_result.sentiment_analysis:null:null}
              />
            </div>
          </div>

          {/* <fieldset className="space-y-1">
            <label className="flex-none my-auto text-base text-[#9CA4AB]">
              Sentiment Type
            </label>
            <div className="flex space-x-3 ">
              <div className="flex items-center gap-x-3">
                <input
                  id="positive"
                  name="sentiment-type"
                  type="radio"
                  checked
                  className="h-4 w-4 border-0 outline-none accent-blue-300 md:accent-blue-500"
                />
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="negative"
                  name="sentiment-type"
                  type="radio"
                  className="h-4 w-4 border-0 outline-none accent-red-300 md:accent-red-500"
                />
              </div>
            </div>
          </fieldset> */}
        </div>
      </div>
    </Layout>
  );
}
