import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { ReactComponent as LabeahLab } from "../assets/icons/LabeahLabLogoSmall.svg";
import { ReactComponent as LabeahLabBig } from "../assets/icons/LabeahLab.svg";
import { ReactComponent as Home } from "../assets/icons/layout/Home.svg";
import { ReactComponent as Phone } from "../assets/icons/layout/Phone.svg";
import { ReactComponent as Uploader } from "../assets/icons/layout/Uploader.svg";
import { ReactComponent as HomeActive } from "../assets/icons/layout/HomeActive.svg";
import { ReactComponent as PhoneActive } from "../assets/icons/layout/PhoneActive.svg";
import { ReactComponent as AiIcon } from "../assets/icons/layout/chip-ai-svgrepo-com.svg";


import { ReactComponent as UploaderActive } from "../assets/icons/layout/UploaderActive.svg";
import { ReactComponent as Logout } from "../assets/icons/layout/Logout.svg";
import { ReactComponent as Settings } from "../assets/icons/layout/Settings.svg";
import { ReactComponent as SettingsActive } from "../assets/icons/layout/SettingsActive.svg";
import { ReactComponent as Calendar } from "../assets/icons/layout/Calendar.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const navigation = [
    {
      name: "General Analytics",
      href: "/analytics",
      icon: Home,
      activeIcon: HomeActive,
      showOnSidebar: true,
    },
    {
      name: "Uploader",
      href: "/uploader",
      icon: Uploader,
      activeIcon: UploaderActive,
      showOnSidebar: true,
    },
    {
      name: "Processed Audios",
      href: "/calls",
      icon: Phone,
      activeIcon: PhoneActive,
      showOnSidebar: true,
    },
    {
      name: "Copilot For Analytics",
      href: "/copilot",
      icon: AiIcon,
      activeIcon: AiIcon,
      showOnSidebar: true,
    },
    {
      name: "Copilot For Analytics",
      href: "/config",
      icon: Home,
      activeIcon: HomeActive,
      showOnSidebar: true,
    },
    
    {
      name: "Settings",
      href: "/settings",
      icon: null,
      activeIcon: null,
      showOnSidebar: false,
    },
    {
      name: "Call Details",
      href: "/callDetails",
      icon: null,
      activeIcon: null,
      showOnSidebar: false,
    },
  ];

  const activePage = navigation.filter(
    (item) => item.href === location.pathname
  )[0];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center mt-5">
                      <LabeahLabBig className="w-1/2" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                {item.showOnSidebar ? (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      location.pathname === item.href
                                        ? "bg-gray-50 text-indigo-600"
                                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    {location.pathname === item.href ? (
                                      <item.activeIcon className="h-6 w-6 shrink-0 " />
                                    ) : (
                                      <item.icon className="h-6 w-6 shrink-0 text-gray-400 " />
                                    )}
                                    {item.name}
                                  </a>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </li>

                        <li className="mt-auto">
                          <a
                            href="/settings"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            {location.pathname === "/settings" ? (
                              <SettingsActive
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                            ) : (
                              <Settings
                                className="h-6 w-6 shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                            Settings
                          </a>
                          <a
                            href="/"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Logout
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-[#F7F8FA] pb-4">
            <div className="flex h-16 shrink-0 items-center mt-2">
              <LabeahLab className="w-full" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="space-y-4 ">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {item.showOnSidebar ? (
                          <a
                            href={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? " border-r-4 border-secondaryDark"
                                : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                              "group flex  p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            {location.pathname === item.href ? (
                              <item.activeIcon className="h-6 w-6 shrink-0 mx-auto" />
                            ) : (
                              <item.icon className="h-6 w-6 shrink-0 text-gray-400 mx-auto" />
                            )}
                          </a>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <a
                    href="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    {location.pathname === "/settings" ? (
                      <SettingsActive
                        className="h-6 w-6 shrink-0 mx-auto"
                        aria-hidden="true"
                      />
                    ) : (
                      <Settings
                        className="h-6 w-6 shrink-0 text-gray-400 mx-auto"
                        aria-hidden="true"
                      />
                    )}
                  </a>
                  <a
                    href="/"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Logout
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600 mx-auto"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-20">
          <div className="sticky top-0 z-40">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-[#FAFAFA] px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-gray-200 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <div className="relative flex flex-1 lg:pl-5 ">
                  <h1 className="my-auto text-lg lg:text-xl font-semibold text-primary">
                    {activePage.name}
                  </h1>
                </div>

                <div className="flex items-center gap-x-4 lg:gap-x-6 lg:px-12">
                  <div className="flex space-x-2">
                    <Calendar className="my-auto" />
                    <label className="my-auto">
                      {moment().format("MMM D, YYYY")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <main className="py-5 bg-[#FAFAFA]">
            <div className="px-4">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
