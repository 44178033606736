import axios from "axios";

import { BASE_URL } from "../constants/config";

axios.defaults.withCredentials = false;
const publicInstance = axios.create({
  baseURL: BASE_URL,
});

publicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
export default publicInstance;
