import React, { useRef, useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";

const WaveComp = ({ audioFile }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = () => {
    if (wavesurfer.current.isPlaying()) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
  };

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "gray",
      progressColor: "#1D75DD",
      cursorColor: "#1D75DD",
      barWidth: 4,
      barGap: 4,
      barRadius: 4,
    });

    wavesurfer.current.load(audioFile);

    wavesurfer.current.on("play", () => {
      setIsPlaying(true);
    });

    wavesurfer.current.on("pause", () => {
      setIsPlaying(false);
    });

    return () => wavesurfer.current.destroy();
  }, [audioFile]);

  return (
    <div>
      <div ref={waveformRef} />

      <button
        onClick={playAudio}
        className={`${
          isPlaying
            ? "border border-primary text-primary"
            : "border border-secondary bg-secondary text-white"
        } p-1 w-28 rounded-xl font-medium mt-5`}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>
    </div>
  );
};

export default WaveComp;
