import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "axios";

import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { ReactComponent as UploadFile } from "../assets/icons/Uploader/UploadFile.svg";

// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
import moment from "moment";


export default function Config() {
  const navigate = useNavigate();
  const cards = [
    {
      title: "Avaya",
      description: "Integrate the speech analytics with Avaya recorder to collect the audio files in realtime way in order to process it.",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2lqPFjolFHebf00V5BPDAWQZojY9nOpDmXQ&s",
    },
    {
      title: "Genesys",
      description: "Integrate the speech analytics with Genesys recorder to collect the audio files in realtime way in order to process it.",
      imageUrl: "https://www.thesmbguide.com/images/Genesys-1024x512-20190228.png",
    },
    {
      title: "Custom Recording / PBX",
      description: "Integrate the speech analytics with custom recorder to collect the audio files in realtime way in order to process it.",
      imageUrl: "https://assets.bigcartel.com/product_images/32367145/Custom_Nameweb.jpg?auto=format&fit=max&h=1000&w=1000",
    },
  ];


  return (
    <>

      <Layout>
        <div className="min-h-[90vh]">
          <div className="bg-white border rounded-xl p-5 text-center font-bold">
            System Config & Integration
          </div>
          <div className=" center text-center p-4  bg-gray-50">
           

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
      {cards.map((card, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col items-center p-4"
        >
          <img
            src={card.imageUrl}
            alt={card.title}
            className="w-full h-32 object-cover"
          />
          <h2 className="text-xl font-semibold mt-4">{card.title}</h2>
          <p className="text-gray-600 mt-2 text-center">{card.description}</p>
          <button className="mt-4 bg-secondary text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600 transition duration-200">
            Configure
          </button>
        </div>
      ))}
    </div>

    <div className="text-left left">
        Integration methods: <br/>
    1. Capture SIP traffic using a SIP listener or recorder <br/>
    2. Access recordings via file transfer (e.g., SFTP) <br/>
    3. Access recordings via APIs <br/>

    </div>

          </div>


         

        </div>
      </Layout>
    </>
  );
}
